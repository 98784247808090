.ArtPageContent {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
}

.ArtTitle {

}

.ArtText {

}

.MediumsList {

}

.MediumListItem {
    display: inline-block;
    border: 1px solid rgb(255, 242, 213);
    padding-left: .4em;
    padding-right: .4em;   
    padding-top: .1em;
    padding-bottom: .1em;
    margin-bottom: -1px;
    background-color: rgba(0, 0, 0, 0.4); /* Black with 80% opacity */

}

/* .MediumListItem:first-of-type {
  border-radius: 10px 0px 0px 0px;
}

.MediumListItem:last-of-type {
  border-radius: 0px 10px 0px 0px;
} */

.MediumListItem:hover {
  cursor: pointer;
  color: rgb(232, 76, 76);
}

.ArtContent {
  border: 1px solid rgb(255, 242, 213);
  overflow: hidden;
  border-radius: 0px 10px 10px 10px;

}

.SubList {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.SubListItem {
    display: inline-block;
    padding-left: .4em;
    padding-right: .4em;   
    padding-top: .1em;
    padding-bottom: .1em;
    background-color: rgba(0, 0, 0, 0.4); /* Black with 80% opacity */

}

.SubListItem:last-of-type {
  border-radius: 0px 10px 0px 0px;
}

.SubListItem:hover {
  cursor: pointer;
}

#selected {
    background-color: rgb(251, 240, 196);
    color: rgb(50, 106, 138);
}


.ImageGallery {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px; /* Adjust the gap as needed */
    overflow: auto; /* Enable vertical scrolling when content exceeds the maximum height */
    border-radius: 0px 10px 10px 10px;
    background-color: rgba(0, 0, 0, 0.4); /* Black with 80% opacity */
    max-height: 75vh;
}

.ImageGallery::after {
  content: "";
  flex-grow: 999;
  flex-shrink: 1;
  flex-basis: auto;
}



/* CSS for square image container */
.image-container {
  position: relative;
  flex: auto;
  overflow: hidden;
  border: 1px solid rgb(255, 242, 213);
  transition: all 0.3s ease; /* Smooth transition effect */
  height: 300px;

  /* flex: 1 1 300px; Adjust base size */
  /* align-items: center;
  justify-content: center; */

  
}
  
/* Apply the object-fit property to the image */
.image-container img {
  object-fit: cover;
    width: 100%;
    height: 100%;

}


  
/* Style the link to open the image in a new tab */
.image-link {
  display: block;
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}














/* Media query for 700px and above */
@media (max-height: 766px) {
  .ImageGallery {
    max-height: 70vh;
    }
}

/* Media query for 700px and above */
@media (max-height: 574px) {
  .ImageGallery {
    max-height: 60vh;
    }
}

.Imagee {
    width: 100%;    
}

.Imagee:hover {
  cursor: pointer;
}


.ScultpoWrap {
    display: inline-block;
    position: relative; /* Needed for the link */
    border: 1px solid rgb(255, 242, 213);
    position: relative;
    flex: auto;
    overflow: hidden;
    border: 1px solid rgb(255, 242, 213);
    transition: all 0.3s ease; /* Smooth transition effect */
    height: 300px;
    aspect-ratio: 1/1;   
}

.ModelViewer {
    width: auto;
    height: auto;
}




/* Add hover effect to the container */
.image-container:hover .image-info {
    height: 20%; /* Height relative to the image */
    opacity: 1;
    transition: all 0.3s ease; /* Smooth transition effect */

}

  /* CSS for the hidden div at the bottom */
.image-info {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7); /* Black with 70% opacity */
    color: rgb(255, 242, 213); /* Text color */
    font-size: 14px; /* Adjust text size as needed */
    text-align: center;
    height: 0;
    opacity: 0;
    transition: all 0.3s ease; /* Smooth transition effect */
}

.image-info div {
  margin:5px;
}
  
  /* Add hover effect to the container */
  .ScultpoWrap:hover .image-info {
    height: 20%; /* Height relative to the image */
    opacity: 1;
    z-index: 2;
  }





  /* Style for the overlay when the ImageViewer is open */
.image-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures the overlay is on top of other content */
    border-radius: 15px;
  }
  
  /* Style for the content within the ImageViewer */
  .image-viewer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    width: -webkit-fill-available;
  }
  
/* Style for the image container within the ImageViewer */
.image-container-big {
    /* border: 1px solid rgb(255, 242, 213); */
    display: flex;
    text-align: center;
    height: 80%;
    padding: 10px;
    max-height: 100%; /* Limit the image to the height of the container */
    justify-content: center;
    overflow: hidden;
    margin: 15px 15px 0px 15px;
    width: -webkit-fill-available;
    align-items: center;
  }
  
  /* Style for the image within the container */
.image-container-big img {
    display: block;
    width: 80%;
    max-width: 100%;
    max-height: 100%;
    height: min-content;
    width: min-content;
    box-shadow: 0 0 10px rgba(237, 237, 237, 0.9);
    object-fit: contain; /* Ensure the image maintains its aspect ratio and fits completely within the container */
  }

  .image-container-big a {
    width: 100%; /* Set the maximum width of the image to 100% of the container width */
    height: 100%; 
    display: contents;
    align-content: center;
  }

  
  /* Style for the navigation buttons */

  .close-button {
    right: 0;
    top: 0;
    position: fixed;
    border: none;
    padding: 15px 20px;
    cursor: pointer;
    margin: 15px;
    border-radius: 5px;
  }




  .image-info-bottom {
    display: flex;
    margin: 15px;
    align-items: center;
    height: 20%;
  }

  .image-info-bottom-desc {
    display: flex;
    align-content: center;
    align-self: center;
    border: 1px solid rgb(255, 242, 213);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    width: 30vw;
    text-align: center;
  }

  .Arrow img {
    width: 50px;
  }

  .Arrow:hover {
    cursor: pointer;
  }


  .artInfo {
    /* color: rgb(255, 242, 213);
    border: rgb(255, 242, 213) solid 1px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    background-color: rgba(0, 0, 0, 0.4); Black with 80% opacity */

}

.artInfo h1 {
    color: rgb(255, 242, 213);
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 32pt;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 10px;
}

.artInfo p {
    color: rgb(255, 242, 213)white;
    text-align: center;
    margin: 4px;
}
