.App {
  display: block;
  background-image: url(./images/homeImages/wall1.png);
  background-color: rgb(51, 51, 60);
  /* background-color: rgb(63, 185, 255); */
  /* background-image: url('./images/homeImages/xiNice.jpg');  Replace with the actual path to your image */
  background-size: cover; /* Adjust the background size as needed */
  background-repeat: no-repeat; 
  height: 100dvh;
  justify-content: center;
  align-items: center;
  font-family:monospace;
  font-size: 1.25em;
}

/* main content*/
.ContentWrapper {
  flex: 0 1 65%;
  backdrop-filter: blur(10px); /* Apply a blur effect to the background */
  padding: 15px; /* Add some padding to the content */
  transition: transform 1s ease; /* Add a smooth transition effect */
  margin-left: 15px;
  border: 2px solid ;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px; /* Adjust the value to control the amount of rounding */
  z-index: 2;
  max-height: 90%;
  height: fit-content;
  overflow: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {

  .ContentWrapper {
    flex: 0 1 90%;
    transition: transform 1s ease; /* Add a smooth transition effect */
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    z-index: 2;
    min-height: 95%;
  }
}

.Layout {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: center;
  height: 100%;
}

.OuterLayout {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: flex-start;
  height: 100%;
  max-height: 100%;
}

#videoPlayer {
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
}

#videoContainer {
  display: block;
  z-index: -1;
  position: absolute;
  /* Set other styling properties like width, height, etc. as needed */
}



::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  border: 1px solid ;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 242, 213); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 242, 213); 
}