.Sidebar {
    flex: 0 1 10%;
    background-color: rgba(0, 0, 0, 0.3); /* Black with 80% opacity */
    backdrop-filter: blur(10px); /* Apply a blur effect to the background */
    transition: transform 1s ease; /* Add a smooth transition effect */
    width: fit-content; /* Fixed width for the sidebar */
    padding: 20px; /* Add some padding to the sidebar content */
    z-index: 2;
    border: 2px solid rgb(255, 242, 213);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
    /* color: rgb(255, 242, 213); */
    display: inline-block;
    border-radius: 15px; /* Adjust the value to control the amount of rounding */
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 10px;
}

.Sidebar h2 {
    text-align: center;
    font-weight: 100;

}



div.Sidebar h1 {
    display: block;
    text-align: center;
    font-size: 2em;
    font-family: 'Courier New', Courier, monospace;
}

div.Sidebar ul {
    display: grid;
    flex-direction: column;
    padding: 0px;
    justify-content: center;
    margin: 0px;
}

div.Sidebar ul li {
    display: grid;
    transition: .5s;
    align-content: center;
    padding: 10px 0px 10px 0px;
}

div.Sidebar ul li a {
    display: inline-flex;
    text-decoration: none;
    font-size: 1.15em;

}

div.Sidebar ul li a:hover span {
    color: rgb(232, 76, 76);
}

div.Sidebar ul li img {
    width: 90px;
    margin: -20px;;
}

div.Sidebar ul li span {
    align-self: center;
    padding-left: 10px;
    color: rgb(255, 242, 213);
}

.SidebarButton {
    justify-content: center;
    height: 32px;
    z-index: 4;
    position: absolute;
}

.SidebarButton span {
    color: rgb(255, 242, 213);
    padding: 12px;
}

.SidebarButton button {
    border: none;
    background-color:rgba(0, 0, 0, 0);
    left: 0;
    position: absolute;
    margin: 10px 0px 0px 10px;
}

.SidebarButton button img {
    height: 32px;
}


#MobileSidebar {
    position: absolute;
    z-index: 3;
    margin-top: 0px;
    transform: translateX(-250px);
}


/* Media query to hide the sidebar on narrow screens (e.g., mobile devices) */
@media (max-width: 768px) {
    .Sidebar {
        display: none;
    }

    #MobileSidebar {
        display: block;
    }
}

@media (min-width: 768px) {
    .SidebarButton {
        display: none;
    }

    #MobileSidebar {
        display: none;
    }
}