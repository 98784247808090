* {
    color: rgb(255, 242, 213);
    
}

div {
    border: rgb(255, 242, 213);
}

text {
}

h1 {
}

h2 {
}

a {
}

a:hover {
    color: rgb(232, 76, 76);
}


p {
}